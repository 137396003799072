body, .footer, .header, .login, .reg-container, .main-content, .content, .form-label-text, .content-footer {
    background-color: black !important;
    color: #fefe01 !important;
    font-size: 18px;
}

a {
	color: #fefe01 !important;
    text-decoration: underline;
}

input, select, textarea {
	background-color: black !important;
	border: 1px solid #fefe01 !important;	
}

.form-container > h1 {color:orange;}

.step-container {background:none;}

.step {
/*	background-color: black !important; */
        background-color: black;
/*	color: #fefe01 !important; */
	color: #fefe01;
	border: solid 2px #fefe01;
}
.step-done {
/*	background-color: #fefe01; */
	background-color: #fefe01 !important;
/*	color: black; */
	color: black !important;	
}
.step-line {
        background-color: #fefe01;
}

.form-header {
	background-color: #fefe01 !important;
    color: black !important;
 }

.checkbox + label::before {border: 1px solid #fefe01;} 

.header a, .footer .links a  {
    font-size: 22px !important;
}

a:hover {
    color: orange !important;
}

a:focus {
    color: #fefe01 !important;
}

button, .button {
    color: black !important;
    text-decoration: none;
    font-size: 22px;
}

button:focus, button:hover, .button:focus, .button:hover {
    color: black !important;
}

a:focus, button:focus, button:hover, .button:focus, .button:hover {
	outline: medium solid #fefe01 !important;
}

#header-links ul li {
    display: block;
}

button.green, button.green:hover, button.green:focus,
.button.green, .button.green:hover, .button.green:focus {
    background: greenyellow;
}

button.blue, button.blue:hover, button.blue:focus,
.button.blue, .button.blue:hover, .button.blue:focus {
    background: cyan;
}

button.gray, .button.gray, button.gray:hover, .button.gray:hover {
    background: #616161;
}

.login, .login a, .login .text, .login .text a {
    color: #fefe01;
}

.login a, .login .form-control {
    font-weight: bold;
}

.login .form-control {
    border-width: 5px;
}

.text-info {
    color: orange;
}

/*
.fs-2 {
    font-size: 2em;
}
*/

.header-btn-box {
    float: none;
    width: 100%;
}

.alert {
    background: inherit;
    border: solid 2px orange;
    color: inherit;
}
